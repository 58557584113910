<template>
  <div class="information">
    <img class="background" src="../../assets/image/background.png" alt srcset />
    <header>
      <img src="../../assets/image/logo.png" alt="" srcset="" />
    </header>
    <van-popup
      v-model="show"
      position="center"
      :style="{ height: '45%', width: '85%' }"
    >
      <div class="main">
        <div class="title">很抱歉没有找到您的信息</div>
        <div class="centent">如您还注册本次峰会您&nbsp;<span @click="register">点击此处</span>&nbsp;注册</div>
        <div class="info">
          如您已注册本次峰会，请您耐心等待,审核信息将会在三个工作日内，以***的方式
        </div>
        <button class="back" @click="back">返回</button>
      </div>
    </van-popup>
    <div class="footer-fill"></div>
    <el-footer :index="2"/>
  </div>
</template>

<script>
import footer from "../../components/footer/footer-tempate.vue";
export default {
  components: {
    "el-footer": footer
  },
  data() {
    return {
      show: true,
    };
  },
  methods:{
    back(){
      this.$router.go(-1);
    },
    register(){
      window.location.href= "http://lxi.me/yz3yb";
    }
  }
};
</script>

<style lang="less" scoped>
.information {
  min-height: 100vh;
  // background-image: url("../../assets/image/background.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  .background {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
    z-index: -1;
  }
  header {
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }
  .box {
    height: 46px;
  }
  .van-hairline--bottom::after {
    border-bottom-width: 0;
  }
  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }
  .van-nav-bar {
    background-color: transparent;
  }
  /deep/ .van-popup {
    background-color: #38224f;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
    border: 1px solid #fff;
    .main {
      width: 85%;
      margin: 0 auto;
      padding: 40px 0 20px 0;
      box-sizing: border-box;
      .back {
        display: block;
        text-align: center;
        width: 60%;
        margin: 15px auto;
        border-radius: 5px;
        border: none;
        height: 35px;
        background-image: linear-gradient(to right, #84569a, #faa170);
      }
      .title {
        padding: 10px 0;
      }
      .centent {
        padding: 10px 0 20px 0;
        span {
          text-decoration: underline;
        }
      }
    }
  }
}
.footer-fill{
  height: 37px;
}
</style>